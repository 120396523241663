import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "OpenHAB v3 Blockly Scripts for your WQHD INSTAR MQTTv5 Broker",
  "path": "/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_IV/",
  "dateChanged": "2022-08-17",
  "author": "Mike Polinowski",
  "excerpt": "I want to use my IN-9408 2k+ with the new Version 3 of OpenHAB. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='OpenHAB v3 Rules for your WQHD INSTAR MQTTv5 Broker' dateChanged='2022-08-17' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to use my IN-9408 2k+ with the new Version 7 of OpenHAB. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_IV/' locationFR='/fr/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_IV/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "openhab-v3-blockly-scripts-for-your-wqhd-instar-mqttv5-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab-v3-blockly-scripts-for-your-wqhd-instar-mqttv5-broker",
        "aria-label": "openhab v3 blockly scripts for your wqhd instar mqttv5 broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB v3 Blockly Scripts for your WQHD INSTAR MQTTv5 Broker`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_I/"
        }}>{`Part I - MQTT Binding Configuration through the Main UI`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_II/"
        }}>{`Part II - MQTT Binding Configuration through the Configuration Files`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_III/"
        }}>{`Part II - MQTT Binding Rules`}</a></li>
      <li parentName="ul">{`Part III - MQTT Binding Rules`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_V/"
        }}>{`Part IV - Groups and Sitemaps`}</a></li>
    </ul>
    <h2 {...{
      "id": "mqtt-binding-rules--scripts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-binding-rules--scripts",
        "aria-label": "mqtt binding rules  scripts permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Binding Rules & Scripts`}</h2>
    <p><strong parentName="p">{`A`}</strong>{`: Part 1 of this tutorial installed the OpenHAB MQTT binding and connected it to the internal broker in our INSTAR WQHD camera using the OpenHab 3 `}<strong parentName="p">{`Main UI`}</strong>{`. The `}<strong parentName="p">{`Part II`}</strong>{` did the same by editing the configuration files of our OpenHab 3 installation. Now it is time to add some automations using the OpenHab `}<strong parentName="p">{`Scripts`}</strong>{` feature.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mqtt-binding-rules--scripts"
        }}>{`MQTT Binding Rules & Scripts`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#scripts"
            }}>{`Scripts`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h3 {...{
      "id": "scripts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#scripts",
        "aria-label": "scripts permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Scripts`}</h3>
    <p>{`For slightly more complicated triggers we can also use `}<strong parentName="p">{`Javascript`}</strong>{` or the `}<em parentName="p">{`assembly block`}</em>{` version of it called `}<strong parentName="p">{`Blockly`}</strong>{`. Before I start I am going to install the MQTT `}<strong parentName="p">{`Block Library`}</strong>{` that will allow us to use our camera's MQTT interface to publish configuration updates:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a0d5af0cedbcdd65ee8082d71faa3b1f/20f89/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACLklEQVQoz1WSMWsUQRiGFwRtgpn5ZnZmdr+5ud2d2b3du9u7nEWygaskgoTkTC6o2NlYCRaW1hY2NoJYJDGFEIkBQTiLmBBFhfgTFPwxsndJwKd/+fje9/EGg0GzGRnTjOPY2aTVyoIgoJQyxqCGUkrm5ubIFLiAUgpAvaqq2u22c84Y0y57aZYZ00BEISQAMMaEkFmaKqWEEHQKAHDOGeNeWZbOOWutxvDx28mtjXsujowxhBAGQBnv9m/8OjsbDofOuTAMfd+nlCg0IsA6nCSJcw4RDw8/bGzcQUSlFJsC3E9dtr+7u7i0FEVRnUeklMoAhQq8Xq9np2jElwcnt0djo1FrXb8NQIC1292znz+qajmaYowBABmEQiqv2+2mWWatbWh8vne0tvUgc4lSihDiA4SMF2X/y/HxcDgsisIY4/sCKFWhFir0BoNBp9NJkgS1fvd6b311LcRQay2krDsBlrWKne3tfr+PiL7vzzpXs8t5nl8Uhk/2T7cePloou1mWRVHEOSPAy/7C39+nKys3W3ke1102gyBoxhYbTS9JEmutsxa1/rTzary+GqIWvk/qqeu28zz/9v5NtbjoXJqmaZJYKUX9swo8e4FGPJpMxpub9UjnhgBhvOyWf04+LldLQsiZKpQQhQ0ZhJdhpzE8mByN795P4mg2KVDKuO9c+uLZ06LIpZRTN9i5JJz/F/7+9fNoNOKc14VNffKFZEJeuXrt+vz8zK1LQQHgHyq5dYlYqW6KAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a0d5af0cedbcdd65ee8082d71faa3b1f/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 230w", "/en/static/a0d5af0cedbcdd65ee8082d71faa3b1f/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 460w", "/en/static/a0d5af0cedbcdd65ee8082d71faa3b1f/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 920w", "/en/static/a0d5af0cedbcdd65ee8082d71faa3b1f/a394e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 1213w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a0d5af0cedbcdd65ee8082d71faa3b1f/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 230w", "/en/static/a0d5af0cedbcdd65ee8082d71faa3b1f/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 460w", "/en/static/a0d5af0cedbcdd65ee8082d71faa3b1f/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 920w", "/en/static/a0d5af0cedbcdd65ee8082d71faa3b1f/68f19/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 1213w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a0d5af0cedbcdd65ee8082d71faa3b1f/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 230w", "/en/static/a0d5af0cedbcdd65ee8082d71faa3b1f/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 460w", "/en/static/a0d5af0cedbcdd65ee8082d71faa3b1f/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 920w", "/en/static/a0d5af0cedbcdd65ee8082d71faa3b1f/20f89/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 1213w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a0d5af0cedbcdd65ee8082d71faa3b1f/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can also use the OpenHab `}<strong parentName="p">{`Rules`}</strong>{` to connect our script to an item - allowing us to trigger it manually with a dummy switch:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e63195f72c74f1bbe18d45bf3a6dea2f/38a65/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABYklEQVQoz32QS27bMBCGdYB2QVIU9SBn+FYkSrKzCJrUBQojN0iMAl202dQH6abIob0oJBSKGwf58C3IGf58ZVrrlFIIIaUESkUN+gyl1DAMx+OxbVuAlxZqHQ1miDgubLdbKaUCXFdYaznnh8PhdDrt9/uyLK21L3nUWd8n57wQgvNCSmn+BxE3m83j42EYBkR81c1ub+92X3YpDSFGa215wbIvL98i66K/nobojTOAqiGEFBcIIS6LhJAMv/8JP56Lh9/lt2dx/4tRogBnFSgFUirr/M+nJ8R/lbVOKc3C9e7q5qseP5npTsWRUspzLoRY79zU9X73uaqqV2+Zw130m7F3GoJFUA1jTBsrqpIQQhcYpfTjB0bfYP7tcZq6rvchAGCe5/Zqkr6v6qZebWR9Pl1kLM+2C13XOecQ57Ax1hrDebFanI1X55Pbto0xOue89wCQ5zmGHoyvZup3ZIz9Be4CVEI/32TxAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e63195f72c74f1bbe18d45bf3a6dea2f/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 230w", "/en/static/e63195f72c74f1bbe18d45bf3a6dea2f/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 460w", "/en/static/e63195f72c74f1bbe18d45bf3a6dea2f/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 920w", "/en/static/e63195f72c74f1bbe18d45bf3a6dea2f/ba661/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 1075w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e63195f72c74f1bbe18d45bf3a6dea2f/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 230w", "/en/static/e63195f72c74f1bbe18d45bf3a6dea2f/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 460w", "/en/static/e63195f72c74f1bbe18d45bf3a6dea2f/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 920w", "/en/static/e63195f72c74f1bbe18d45bf3a6dea2f/4690a/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 1075w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e63195f72c74f1bbe18d45bf3a6dea2f/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 230w", "/en/static/e63195f72c74f1bbe18d45bf3a6dea2f/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 460w", "/en/static/e63195f72c74f1bbe18d45bf3a6dea2f/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 920w", "/en/static/e63195f72c74f1bbe18d45bf3a6dea2f/38a65/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 1075w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e63195f72c74f1bbe18d45bf3a6dea2f/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`I can add this switch as a trigger for both the `}<strong parentName="p">{`ON`}</strong>{` and `}<strong parentName="p">{`OFF`}</strong>{` state:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0c5b2a2ce89441b519357c50d1b489b9/1132d/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABcklEQVQoz1WOT4vbQAzFfV8aO8EejTQaafxn7Ni7IU4odQLZLLnsobBQ6KnfpMf2mxfHNLCP30GC9/SU1HVdlmUTo7AbqjBLtFIty6CqMcZpmvb7fQjBe693efFtkKGU5Hg8Hg6HcRyJiJwXL1KHWKkyO/bMjEgAFpEQyTl2jokYyVmkRDUYAwBA5Nhx8GX3uiuPLRpyzIvbOf9/mE/Wbc9aMXNyu92GYYgxikie5wCAxtoC5iZEuv9jLT5ARAAwpiiKIhledk3smtixl9VqFULZ94MXEVER7fthGJ5DKJdVRJl9XTeIlGVpsovauKL1xts8yzIv2nZbMGDu2LmL7Cdh0zSIuF6vk/bXX/vxG3/+sdOP9MtTENl2HQA8vPlnGWOcc3mebzabpL18yNf3MH2n7pCmqy3R9XS+XN8ul9fr9e10OldVzey9l4VlNgbm5m/jy66ropAnSNNMG53O07gfY2y3276uG9XwSC6IKMAc/gcFdkLnqSWIowAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0c5b2a2ce89441b519357c50d1b489b9/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 230w", "/en/static/0c5b2a2ce89441b519357c50d1b489b9/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 460w", "/en/static/0c5b2a2ce89441b519357c50d1b489b9/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 920w", "/en/static/0c5b2a2ce89441b519357c50d1b489b9/31e76/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 1158w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0c5b2a2ce89441b519357c50d1b489b9/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 230w", "/en/static/0c5b2a2ce89441b519357c50d1b489b9/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 460w", "/en/static/0c5b2a2ce89441b519357c50d1b489b9/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 920w", "/en/static/0c5b2a2ce89441b519357c50d1b489b9/35062/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 1158w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0c5b2a2ce89441b519357c50d1b489b9/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 230w", "/en/static/0c5b2a2ce89441b519357c50d1b489b9/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 460w", "/en/static/0c5b2a2ce89441b519357c50d1b489b9/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 920w", "/en/static/0c5b2a2ce89441b519357c50d1b489b9/1132d/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 1158w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0c5b2a2ce89441b519357c50d1b489b9/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And as `}<strong parentName="p">{`Action`}</strong>{` we now have to choose `}<strong parentName="p">{`Run Script`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3504a6169f4f5c2535015fca3d657b78/21335/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABkUlEQVQoz02S247UMAyGe02SpnWck53EHaZiFu3OQWiR4IYrkHj/J4Kky4pPUU6S89u/MxFRCIGZEX0AiB4dokcMHr33iEgDGLgBAAQH0cFUSmFmEUHED0rbpbNau9pZG7Ms1lqrtTaDeZ6ttfNYtDETgNPaKKXQQU4pEzFzjLGUer2+iAgR5QERee+t7e8jotZ6ulw+tda8R8rUWquDNhDZam1vxz5JSunIP8ZojJlkO2Vih5iJtu2UM/038vs+dmEegmpdV0SvlJqydwFmXExO8bzvzjnvAw6cc8cmhFCcC4jLsmitR9m2B9PtB1y+28s34I+FqNR2vz+27cRccqZSaqntcX/stZVSET38o9ccvvxerj/N9Re0yxH8/PzSmnCpb8GlPT195mEFgFNK6UFX5ugSLn5RCGtK3W8RyZlSyiHE4+a870ycUgaAo1vWjrSbbKU29AG9H9622+0mspWhXGsV2V5fv7YmtYpz7r3no+acY4zY/xV2Y4kPne5wTKO7fD7vNG4A4Pgq8zz/Df4DCZY8rO9/le0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3504a6169f4f5c2535015fca3d657b78/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 230w", "/en/static/3504a6169f4f5c2535015fca3d657b78/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 460w", "/en/static/3504a6169f4f5c2535015fca3d657b78/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 920w", "/en/static/3504a6169f4f5c2535015fca3d657b78/63939/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 1082w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3504a6169f4f5c2535015fca3d657b78/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 230w", "/en/static/3504a6169f4f5c2535015fca3d657b78/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 460w", "/en/static/3504a6169f4f5c2535015fca3d657b78/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 920w", "/en/static/3504a6169f4f5c2535015fca3d657b78/6fed8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 1082w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3504a6169f4f5c2535015fca3d657b78/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 230w", "/en/static/3504a6169f4f5c2535015fca3d657b78/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 460w", "/en/static/3504a6169f4f5c2535015fca3d657b78/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 920w", "/en/static/3504a6169f4f5c2535015fca3d657b78/21335/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 1082w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3504a6169f4f5c2535015fca3d657b78/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here we can select the scripting language we want to use - choose `}<strong parentName="p">{`Design with Blockly`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/419b7b54c8fdf41b820e01d8c3fb9fdf/f2d92/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABTklEQVQoz0XRy67TMBAG4OzrxHd7LvE4bqlAhBJ6KEgIFuxA8P7vg+KInk+zGEu2NP5niCn5ELTW2pgcI0AmImYGABG53W4iQkSICIhCSCkaY6y1McbhfD5fr1dm9t4TUWut1kVEStmr1qU35TiKVAS01lrnIOeBeV5aAyTvAxKL1Hkuz+pD7Ii493MIUSlljIkpDdkbiC457bWSmdd1dc4Zrb0xzryy/03TpJTS3ZDefaX1m3vzmNomb1++//j56X5/+bi9X5bSBz1Qx8w55z71bnD3P/Hz32n7Pd5+pbYutZZS9q9KBXwVu5SStfZ0OqluwGg5h+x1NCqFsK4fHo8vrTVmRqTn41Jk6WJM4zhO3TAXkVp5Lj5EQNq2ferL5dLaWaQeIR+Bz/1qSvl0UmM3EO45AoD3PqXUVwrPVQMgHBBDCN57Y8w0TUdg/wCZvTZGIos/8QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/419b7b54c8fdf41b820e01d8c3fb9fdf/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 230w", "/en/static/419b7b54c8fdf41b820e01d8c3fb9fdf/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 460w", "/en/static/419b7b54c8fdf41b820e01d8c3fb9fdf/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 920w", "/en/static/419b7b54c8fdf41b820e01d8c3fb9fdf/fa017/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 1027w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/419b7b54c8fdf41b820e01d8c3fb9fdf/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 230w", "/en/static/419b7b54c8fdf41b820e01d8c3fb9fdf/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 460w", "/en/static/419b7b54c8fdf41b820e01d8c3fb9fdf/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 920w", "/en/static/419b7b54c8fdf41b820e01d8c3fb9fdf/25665/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 1027w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/419b7b54c8fdf41b820e01d8c3fb9fdf/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 230w", "/en/static/419b7b54c8fdf41b820e01d8c3fb9fdf/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 460w", "/en/static/419b7b54c8fdf41b820e01d8c3fb9fdf/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 920w", "/en/static/419b7b54c8fdf41b820e01d8c3fb9fdf/f2d92/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 1027w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/419b7b54c8fdf41b820e01d8c3fb9fdf/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This will open the `}<strong parentName="p">{`Blockly Editor`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/094c331925396b8cda7805db11e1ff2f/4ad3a/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABi0lEQVQoz43NS27TUBiGYW+CBmr7+Nx/3+1gOyf1sUMaRJzQ3EqQHEFQI1ViECEkxgygYgWMWAI7QKyAAWLCHhiyCASdVNzUV9/00WdIKcMwjOPYDwLf9/I0AdcNPG9cFPN+f5plm0379PmzfqVtyyaEYIwdjBnGQByDUiqlBCkxIYWumqZJb3cHSr3f7T7u9x/2+89fPn39/q092x7cOMAYo1/ZCFkIGVEUAYBpWqZp6bKuqypKkjLP39w/edu273a780ebh08eH2ltmqbjOOhKhq7qO8OhHwQAUCk1mUx0WcZxQhmnXFAmzEPrVucmsp3f5E/sSu6D4MRmjpUk8XK5Wq/XSinGuBBSCAngup4vhER/ZMjeSOTHONUoqZLi6HS1Go+bNE05F5f4cozxv2DWXrD2orN42Tl9FQ2XJ82949EoDMOr8p/YlywALohN0WE3jWfzxXy+yLPsWs9ZXhS9nut5lLJalbPZVJeqm8bXwgAuADDGCXEGd7cPtq8H0xdFveGcCQH/xz8AhNdue/RzLwkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/094c331925396b8cda7805db11e1ff2f/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 230w", "/en/static/094c331925396b8cda7805db11e1ff2f/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 460w", "/en/static/094c331925396b8cda7805db11e1ff2f/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 920w", "/en/static/094c331925396b8cda7805db11e1ff2f/b019a/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 1152w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/094c331925396b8cda7805db11e1ff2f/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 230w", "/en/static/094c331925396b8cda7805db11e1ff2f/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 460w", "/en/static/094c331925396b8cda7805db11e1ff2f/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 920w", "/en/static/094c331925396b8cda7805db11e1ff2f/b2d4b/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 1152w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/094c331925396b8cda7805db11e1ff2f/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 230w", "/en/static/094c331925396b8cda7805db11e1ff2f/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 460w", "/en/static/094c331925396b8cda7805db11e1ff2f/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 920w", "/en/static/094c331925396b8cda7805db11e1ff2f/4ad3a/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 1152w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/094c331925396b8cda7805db11e1ff2f/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here we can create the same function we build earlier using the `}<em parentName="p">{`Things`}</em>{`, `}<em parentName="p">{`Channels`}</em>{` and `}<em parentName="p">{`Rules`}</em>{`. As a trigger we will check if our `}<strong parentName="p">{`Test-Button`}</strong>{` has the state `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ON`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`OFF`}</code>{`. If this is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{` I want to publish updates to the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Multimedia/Privacy_Mask/"
      }}>{`Privacy Masks`}</a>{` topics and activate or deactivate them all at once:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/30956cbeeaa664d4e9b42f85fae667de/74d4e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAC8ElEQVQoz23S6VMSYRwHcP4JrRzZfZ5nd917UXcJO4REdhcCQYIs6VJYF9hNEjJSdLtsLJsoLZ0Ks5psKs3seNNM/XMNVnZM3/ef+Z0ujuMoimJZFiHUxbMdHTRFkrQgS/JBxdPt9R1Qo4M9IQ0jyL2trW379gEcAABoiLMIuCRJEkWR5zjGI4WPRn0+nyjyiTOVRnl2fTT79duX5c2tysLtkfHzWiTcH9YBBJjbjWGYG8NcJEm1tbW1tLQIUpemaoos86IwlLHfGeZH02ws3r14fdYq2fmS3af2H/T3AgjdOxjDMFcgEJBEESKkSFJE1/3+AC/w6Yy9YRY3R0bevFi9ubw06dQmpqvHTg5FBxPwT8ywLElSCBE0zQSDwWQyebj3UGrY2jCL26b5bGXp0tzVQum8aRfVSDjQH9xtGyHkkniGBG4Cb2coMhRSBwYG9vf4Uhn7dc58Nzr6srEy/+ihMz9XvebEkolQRN+tTBCEq+Pc3T3pm60n7sBIIayp8XicE/j0sLVlFraN3Prqo4vXnayZM6x8PHVMjx0Ff2JO6SVELxAUSuxWVTWdTvt83sFTpbXixGa+sLhwy6nfm1q4lS2Nezo7RY+E43hz1W53E3d5JIFjIY518qKu6Zqm+/YryXOVJxXnxajx8dOH+dVnRq02ZORCuhrUtd2Zm1gQRZpmAITdUncsGgv4/QzHHs/YG1ljO5tde3j/Sv32lFObnJ0Ox6JHQkEA4W+MECIIAiHEsIx3J4IopDP2W7P43jBeP2/MPbhfdWbKtcuDx1OReOyvhRE7QQixLCvLsqIoP/FYcSuXbSzWy1dnDSs/UhgLhbXeviMAgCbGm1/6GzMMI8uy9xfeyOffj+VerT2+sVSvOrXydDWWiKu/TgV28h/Mi8KJ09an8cLnfPbDm6dX6nesygWrciF1ciiaTAAI2tvbAQAQwn/xj7ZTp63ls5mVU+mZcmF8ujo5M2VXJvzBvp7DhzpomqIoCCGO498BVpT6PaSC3HgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/30956cbeeaa664d4e9b42f85fae667de/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.avif 230w", "/en/static/30956cbeeaa664d4e9b42f85fae667de/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.avif 460w", "/en/static/30956cbeeaa664d4e9b42f85fae667de/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.avif 920w", "/en/static/30956cbeeaa664d4e9b42f85fae667de/189ef/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.avif 1157w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/30956cbeeaa664d4e9b42f85fae667de/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.webp 230w", "/en/static/30956cbeeaa664d4e9b42f85fae667de/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.webp 460w", "/en/static/30956cbeeaa664d4e9b42f85fae667de/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.webp 920w", "/en/static/30956cbeeaa664d4e9b42f85fae667de/3e180/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.webp 1157w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/30956cbeeaa664d4e9b42f85fae667de/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png 230w", "/en/static/30956cbeeaa664d4e9b42f85fae667de/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png 460w", "/en/static/30956cbeeaa664d4e9b42f85fae667de/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png 920w", "/en/static/30956cbeeaa664d4e9b42f85fae667de/74d4e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png 1157w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/30956cbeeaa664d4e9b42f85fae667de/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`configuration`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
triggers`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span>{`
    configuration`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`
      itemName`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` MqttTest
      command`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ON`}</span>{`
    type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` core`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ItemCommandTrigger
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"3"`}</span>{`
    configuration`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`
      itemName`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`TEST`}</span>{`
      command`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`OFF`}</span>{`
    type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` core`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ItemCommandTrigger
conditions`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
actions`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` inputs`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2"`}</span>{`
    configuration`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`
      blockSource`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`xml xmlns`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"https://developers.google.com/blockly/xml"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`block
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"controls_if"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cSbJV5#tLW22bEPP#K,R"`}</span>{` x`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-111"`}</span>{` y`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-459"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`mutation
        elseif`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`mutation`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"IF0"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`block type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"logic_compare"`}</span>{`
        id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"6kg-ekZ-uf22de+9R$Eb"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"OP"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`EQ`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"A"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`block type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"oh_getitem_state"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"H8Q{j,gYqdU(H(%W\`q{i"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"itemName"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"oh_item"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Fm@Ca0#CJ(+RR]CS,1#C"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"itemName"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`TEST`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`block`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"B"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`block type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h?oaJtVOKd,_:O7az7L|"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`ON`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`block`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`block`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`statement
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"DO0"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`block type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt_publish"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"LH#Ceyo=C)p6kSqWRENK"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"RETAIN"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`FALSE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MESSAGE"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{`
        id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Vg|L$du5$O\`+t5@oji6U"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TOPIC"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"_.q@u{=@x]E8#-o7eEaS"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`cameras`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`115`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`multimedia`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`privacy`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`region1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`raw`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"BROKER"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"oh_thing"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"nm[U)Yo0oW=$LWm9k1C~"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"thingUid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`mqtt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`broker`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`cameraBroker115`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`next`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`block
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt_publish"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"]N@-[=+R7QJW+}e1i}{4"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"RETAIN"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`FALSE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MESSAGE"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{`
        id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"UZOJ*R(wnI](:_v+MF$|"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TOPIC"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"#_LE\`Q-C!vb;l~Z4#sFZ"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`cameras`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`115`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`multimedia`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`privacy`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`region2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`raw`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"BROKER"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"oh_thing"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"\`ARr3SX6FJPI_mI@HvwF"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"thingUid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`mqtt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`broker`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`cameraBroker115`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`next`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`block
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt_publish"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"l6z|Bfjy=gw)mn^#Ge$Q"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"RETAIN"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`FALSE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MESSAGE"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{`
        id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`")dN3H}?E!Vy)k-PX0i-S"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TOPIC"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"XR@I55_PYr6wNnZsY,=e"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`cameras`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`115`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`multimedia`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`privacy`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`region3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`raw`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"BROKER"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"oh_thing"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`":C!gTth1Q;-eXuK*}NME"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"thingUid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`mqtt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`broker`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`cameraBroker115`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`next`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`block
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt_publish"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"FFH0!nzU=]}vHQ27aLU+"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"RETAIN"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`FALSE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MESSAGE"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{`
        id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"xBCTX?W][){iIBw$c/s4"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TOPIC"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"k}-3zEP{6s8}$J)~a|tJ"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`cameras`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`115`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`multimedia`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`privacy`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`region4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`raw`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"BROKER"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"oh_thing"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0QQ%UDrhFFv83|^-;PIP"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"thingUid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`mqtt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`broker`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`cameraBroker115`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`block`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`next`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`block`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`next`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`block`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`next`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`block`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`statement`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"IF1"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`block type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"logic_compare"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"CFBz8y(CANbJ~6JvCLDz"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"OP"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`EQ`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"A"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`block type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"oh_getitem_state"`}</span>{`
        id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"hi\`bv3dO@kDX%hjx?\`le"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"itemName"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"oh_item"`}</span>{`
        id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"~A@HRXzSkA]*7U~_=O9w"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"itemName"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`TEST`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`block`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"B"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`block type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Rw~iwC%:oc6Y3_u+gUt1"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`OFF`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`block`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`block`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`statement
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"DO1"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`block type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt_publish"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"^x4{d9[T=:9OaN0RzIpI"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"RETAIN"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`FALSE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MESSAGE"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{`
        id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"QetksPK5^XJ]5oz,mVE~"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TOPIC"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"%MECdsP\`[Q?D@?e:1+kP"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`cameras`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`115`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`multimedia`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`privacy`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`region1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`raw`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"BROKER"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"oh_thing"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"=WE-0+QfYl8~J4]:1IfI"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"thingUid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`mqtt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`broker`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`cameraBroker115`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`next`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`block
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt_publish"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"%Fd4l:Ueh-T^@a)ke2L="`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"RETAIN"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`FALSE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MESSAGE"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{`
        id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`")~ogNT6#T:ytXIg8|\`^k"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TOPIC"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"lBbVb6}WtmH;8,QM|KA^"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`cameras`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`115`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`multimedia`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`privacy`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`region2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`raw`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"BROKER"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"oh_thing"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"@$/#Z}f;AJK;)mJ**0i_"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"thingUid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`mqtt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`broker`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`cameraBroker115`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`next`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`block
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt_publish"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"~uig{I~X1z9S,0DK,t1t"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"RETAIN"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`FALSE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MESSAGE"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{`
        id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`";;6(@Czch6^Y!uo/N+oD"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TOPIC"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"NGvpIToFT=|:U}ui4?:\`"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`cameras`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`115`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`multimedia`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`privacy`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`region3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`raw`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"BROKER"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"oh_thing"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-Q!n!(g!*,tY.kp-oPMG"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"thingUid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`mqtt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`broker`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`cameraBroker115`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`next`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`block
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt_publish"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"$U[Wo=635Y-lNQbkGnD8"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"RETAIN"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`FALSE`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MESSAGE"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{`
        id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"$aQt[J!qmmgh[tW?G0RF"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TOPIC"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`",y$q4b6CO2wCSM29~VT9"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`cameras`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`115`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`multimedia`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`privacy`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`region4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`raw`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`value
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"BROKER"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`shadow type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"oh_thing"`}</span>{` id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"wUKer5jv(80p1hn?l5Cv"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`field
        name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"thingUid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`mqtt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`broker`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`cameraBroker115`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`field`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`shadow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`value`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`block`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`next`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`block`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`next`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`block`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`next`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`block`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`statement`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`block`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`xml`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` application`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`javascript
      script`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` things `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Java`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'org.openhab.core.model.script.actions.Things'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`



        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`itemRegistry`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'TEST'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'ON'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          things`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getActions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt:broker:cameraBroker115'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`publishMQTT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'cameras/115/multimedia/privacy/region1/enable/raw'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'FALSE'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'TRUE'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
          things`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getActions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt:broker:cameraBroker115'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`publishMQTT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'cameras/115/multimedia/privacy/region2/enable/raw'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'FALSE'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'TRUE'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
          things`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getActions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt:broker:cameraBroker115'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`publishMQTT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'cameras/115/multimedia/privacy/region3/enable/raw'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'FALSE'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'TRUE'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
          things`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getActions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt:broker:cameraBroker115'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`publishMQTT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'cameras/115/multimedia/privacy/region4/enable/raw'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'1'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'FALSE'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'TRUE'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`itemRegistry`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'TEST'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'OFF'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          things`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getActions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt:broker:cameraBroker115'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`publishMQTT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'cameras/115/multimedia/privacy/region1/enable/raw'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'0'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'FALSE'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'TRUE'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
          things`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getActions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt:broker:cameraBroker115'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`publishMQTT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'cameras/115/multimedia/privacy/region2/enable/raw'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'0'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'FALSE'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'TRUE'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
          things`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getActions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt:broker:cameraBroker115'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`publishMQTT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'cameras/115/multimedia/privacy/region3/enable/raw'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'0'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'FALSE'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'TRUE'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
          things`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getActions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mqtt:broker:cameraBroker115'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`publishMQTT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'cameras/115/multimedia/privacy/region4/enable/raw'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'0'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'FALSE'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'TRUE'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` script`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ScriptAction`}</code></pre></div>
    <p>{`Now all you have to do is to toggle the test switch to either activate or deactivate all privacy masks! You can now `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/#addressing-the-right-camera"
      }}>{`take control over every MQTT camera`}</a>{` that is connected to your MQTT broker. Replace the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/"
      }}>{`MQTT Topic`}</a>{` with any topic you want to use. And of course you can also replace the triggere with Item channels or other automations.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      